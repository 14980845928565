<template>
  <div class="columns">
    <div class="column is-8 is-offset-2">
      <b-field label="School Class">
        <b-select placeholder="Select a class" v-model="schoolClassId" expanded>
          <option
            v-for="schoolClass in schoolClasses"
            :key="schoolClass.id"
            :value="schoolClass.id"
          >
            {{ schoolClass.name }}
          </option>
        </b-select>
      </b-field>
      <b-field label="Students In Class">
        <b-select placeholder="Select a student" v-model="studentId" expanded>
          <option
            v-for="student in studentsInClass"
            :key="student.id"
            :value="student.id"
          >
            {{ student.name }}
          </option>
        </b-select>
      </b-field>
      <button
        type="submit"
        class="button btn-120 is-primary is-capitalized is-pulled-right mt-4"
        @click="addStudent()"
      >
        Submit
      </button>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { crudNotification } from '../../assets/js/notification'
import SCHOOL_CLASSES from '../../../graphql/school_class/SchoolClasses.gql'

export default {
  data() {
    return {
      schoolId: null,
      parentId: null,
      pageTitle: 'Parent Info - Add Students',
      schoolClasses: [],
      schoolClassId: null,
      studentsInClass: [],
      studentId: null,
    }
  },
  watch: {
    schoolClassId(value) {
      if (!value) return
      if (this.schoolClasses.length == 0) return

      this.$apollo.addSmartQuery('studentsInClass', {
        query: gql`
          query studentsInClass($schoolClassId: Int!) {
            studentsInClass(schoolClassId: $schoolClassId) {
              id
              name
            }
          }
        `,
        variables: {
          schoolClassId: parseInt(value),
        },
      })
    },
  },
  mounted() {
    this.$store.commit('setPageTitle', this.pageTitle)
    this.schoolId = parseInt(this.$route.params.school_id)
    this.parentId = parseInt(this.$route.params.id)

    this.$store.commit('setSubMenus', [
      {
        name: "Parent's Info",
        route: `/school/${this.schoolId}/parent_info/${this.parentId}`,
      },
    ])

    this.$apollo.addSmartQuery('schoolClasses', {
      query: SCHOOL_CLASSES,
      variables: {
        schoolId: this.schoolId,
      },
    })
  },
  methods: {
    addStudent() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation AddParent($id: Int!, $parentId: Int!) {
              addParent(input: { id: $id, parentId: $parentId }) {
                student {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: parseInt(this.studentId),
            parentId: this.parentId,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.addParent.errors,
            'Successfully added.'
          ).then(() => {
            this.$router.replace(
              `/school/${this.schoolId}/parent_info/${this.parentId}`
            )
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
}
</script>
